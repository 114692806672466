@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;600&display=swap');



h1 {
  font-family: 'Inknut Antiqua', serif;
  font-weight:900;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2, h3 {
  font-family: 'Inknut Antiqua', serif;
  font-weight:300;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul, a {
  font-family: 'Encode Sans', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-family: 'Encode Sans', sans-serif;
  font-size: 70%;
  font-weight: 600;
  font-size: calc(10px + 2vmin);
  color: white;
}

.greeting {
  font-size: 5rem;
}

body{
  font-family: arial;
}
.custom-section{
  text-align: center;
  font-size: 4em;
  color: #fff;
}

.actions{
  list-style-type: none;
}
.actions button{
  padding: .93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  border: 0;
  color: #fff;
  margin: 7px;
  font-size: 15px;
  cursor: pointer;
}
.actions button:hover{
  background: #fff;
  color: #35495e;
}

.nav-link{
  color: #fff;
}

.btn.btn-secondary{
  font-family: 'Inknut Antiqua', serif;
  border-radius: 0;
  background-color: #040740;
}

.dive-text{
  color: #fff;
  font-size: 1.1em;
  font-family: 'Encode Sans', sans-serif;
  line-height: 200%;
}

.learn-more{
  font-family: 'Encode Sans', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: rgba(66,31,113,0.9);
  letter-spacing: 10px;
}

.delegate-details{
  color: rgba(66,31,113,0.9);
  font-size: 1rem;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    pointer-events: none;
}
.fabar{
  color: #fff;
  background: rgba(66,31,115,0.7);
  border: none;
  padding: 8px 20px;
  font-weight: 600;
}
.dropdown-menu{
  background: rgba(66,31,115,0.7);
  padding-left: 20px;
}

div.card{
  /* background: rgb(255, 255, 255, 0.7); */
  text-align: left;
}
.card p{
  font-size: 16px;
  text-align: justify;
  color: #040740;
}
.color{
  color: #f3ee4b;
}
.card h5{
  font-size: 18pxpx;
  font-weight: 600;
}
.menu{
  color: white;
}